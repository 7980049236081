import React from 'react';
import {
  Box,
  Grid,
  Flex,
  Typography,
  H1,
  H2,
  H3,
  P,
  Link,
  colors,
  spacing,
  useI18nContext,
} from '@procore/core-react';
import { ArrowRight } from '@procore/core-icons';
import * as Styles from '@/react/styles';

const PricingSection = ({
  pricingDescription,
  subscriptionRequired,
  connectorRequired,
  hasTrials,
  pricingUrl,
  email,
  soldBy,
  pricing,
}) => {
  const i18n = useI18nContext();

  return (
    <>
      {!pricingDescription && (
        <Flex
          direction="row"
          padding="xl"
          gap="lg"
          data-testid="pricing-not-available-section"
        >
          <img
            src="/images/privacy-icon.png"
            alt=""
            style={{ width: '96px', height: '96px' }}
          />
          <Box display="block">
            <H2>{i18n.t('appDetails.pricing.titleNoPricing')}</H2>
            <P>
              {i18n.t('appDetails.pricing.descriptionNoPricing')}
              <Link href={`mailto:${email}`}>
                <Typography intent="body" style={{ color: `${colors.blue45}` }}>
                  {i18n.t('appDetails.pricing.contactPartner')}
                </Typography>
              </Link>
            </P>
          </Box>
        </Flex>
      )}

      {pricingDescription && (
        <Box
          display="flex-column"
          padding="xxl"
          margin="lg"
          alignContent="flex-start"
          justifyContent="flex-start"
        >
          <Grid
            gutterX={{ tabletLg: 'xxl' }}
            gutterY={{ tabletSm: 'xl' }}
            data-testid="pricing-section"
          >
            <Grid.Row>
              <Grid.Col colWidth={{ tabletLg: 6, tabletSm: 12 }}>
                <H1>{i18n.t('appDetails.pricing.title')}</H1>
                <Box marginTop="sm">
                  <H3>
                    {i18n.t('appDetails.pricing.soldBy')}
                    {soldBy}
                  </H3>
                  <P>{pricingDescription}</P>
                  {pricingUrl && (
                    <H2>
                      <Styles.OrangeLink href={pricingUrl} target="_blank">
                        {i18n.t('appDetails.pricing.website')}
                        <ArrowRight
                          style={{
                            paddingTop: `${spacing.sm}px`,
                            fontWeight: 'bold',
                          }}
                        />
                      </Styles.OrangeLink>
                    </H2>
                  )}
                </Box>
              </Grid.Col>

              <Grid.Col colWidth={{ tabletLg: 6, tabletSm: 12 }}>
                <H2>{i18n.t('appDetails.pricing.faqs')}</H2>

                <Box
                  padding="md"
                  marginTop="sm"
                  marginBottom="xl"
                  style={{ backgroundColor: `${colors.gray96}` }}
                >
                  <Typography intent="body" color="gray30">
                    {i18n.t('appDetails.pricing.faqsHeaderPart1')}
                  </Typography>
                  <Link href="#support-section">
                    <Typography
                      intent="body"
                      style={{
                        color: `${colors.blue45}`,
                        textDecoration: 'underline',
                      }}
                    >
                      {i18n.t('appDetails.pricing.faqsHeaderPart2')}
                    </Typography>
                  </Link>
                  <Typography intent="body" color="gray30">
                    {i18n.t('appDetails.pricing.faqsHeaderPart3')}
                  </Typography>
                </Box>

                <Box marginBottom="xl">
                  <H3>
                    {i18n.t(
                      'appDetails.pricing.subscriptionRequired.description'
                    )}
                  </H3>
                  <P>
                    {i18n.t(
                      `appDetails.pricing.subscriptionRequired.${
                        subscriptionRequired ? 'yes' : 'no'
                      }`
                    )}
                  </P>
                </Box>

                <Styles.Divider color={colors.gray70} />

                <Box marginBottom="xl" marginTop="xl">
                  <H3>
                    {i18n.t(
                      'appDetails.pricing.thirdPartyConnectorRequired.description'
                    )}
                  </H3>
                  <P>
                    {i18n.t(
                      `appDetails.pricing.thirdPartyConnectorRequired.${
                        connectorRequired ? 'yes' : 'no'
                      }`
                    )}
                  </P>
                </Box>

                <Styles.Divider color={colors.gray70} />

                <Box marginTop="xl">
                  <H3>
                    {i18n.t('appDetails.pricing.freeTrialOffered.description')}
                  </H3>
                  <P>
                    {i18n.t(
                      `appDetails.pricing.freeTrialOffered.${
                        hasTrials ? 'yes' : 'no'
                      }`
                    )}
                  </P>
                </Box>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default PricingSection;
