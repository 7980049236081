import React from 'react';
import PT from 'prop-types';
import {
  Box,
  Breadcrumbs,
  colors,
  Flex,
  H2,
  LinkButton,
  Page,
  Pill,
  Typography,
  useI18nContext,
  Tooltip,
  Popover,
} from '@procore/core-react';
import {
  Building,
  ChevronLeft,
  Download,
  Help,
  ShieldStar,
} from '@procore/core-icons';
import ContactForm from './ContactForm';
import {
  HeaderContainer,
  HeaderText,
  MediumLogoWrapper,
  PageHeader,
  ResponsiveFlex,
  SmallLogo,
  TruncatedText,
} from '@/react/styles';
import SecurityBadge from './SecurtyBadge';

const AppHeader = ({
  app,
  companyName,
  getActionButton,
  metricsData = {},
  sendAnalytics,
  user,
}) => {
  const i18n = useI18nContext();
  const {
    built_by,
    contact_us_email,
    create_lead,
    developer_app_id,
    status,
    name,
    search_description,
    small_logo,
    small_logo_url,
    price_button,
    seller,
    security_questionnaire_visible,
    direct_installable,
  } = app;

  const installCount =
    metricsData?.app_installations?.rows?.filter((installation) => {
      return (
        installation.status !== 'uninstalled' && !installation.company_is_demo
      );
    })?.length || 0;

  const headerIcons = [
    {
      id: 'built-by',
      Icon: () => <Building />,
      translation: 'builtBy',
      value: built_by,
      isVisible: true,
    },
    {
      id: 'supported-by',
      Icon: () => <Help />,
      translation: 'supportedBy',
      value: seller || built_by,
      isVisible: true,
    },
    {
      id: 'install-count',
      Icon: () => <Download />,
      translation: 'installs',
      value: i18n.toNumber(installCount, { precision: 0 }),
      isVisible: direct_installable,
    },
  ];

  return (
    <Page data-testid="show-header" style={{ width: '100%' }}>
      <PageHeader>
        <HeaderContainer>
          <Box padding="md none">
            <Page.Breadcrumbs margin="xl">
              <Breadcrumbs>
                <LinkButton
                  href="/apps"
                  variant="tertiary"
                  icon={<ChevronLeft color={colors.gray15} />}
                >
                  <Typography intent="h3" color="gray15" weight="bold">
                    {i18n.t('global.appMarketplace')}
                  </Typography>
                </LinkButton>
              </Breadcrumbs>
            </Page.Breadcrumbs>
          </Box>

          <ResponsiveFlex display="flex" justifyContent="space-between">
            <Box display="flex" flexWrap="wrap">
              <MediumLogoWrapper
                marginRight="xl"
                justifyContent="center"
                alignItems="center"
              >
                <SmallLogo
                  src={
                    small_logo_url ||
                    small_logo?.url ||
                    '/images/logo-placeholder.png'
                  }
                />
              </MediumLogoWrapper>

              <Page.Title>
                <HeaderText data-testid="app-name">{name}</HeaderText>
                <H2 data-testid="app-search-description">
                  {search_description}
                </H2>

                <ResponsiveFlex
                  display="flex-row"
                  justifyContent="flex-start"
                  gap="lg"
                  marginTop="md"
                  flexWrap="wrap"
                >
                  {headerIcons.map(
                    ({ id, Icon, translation, value, isVisible }) =>
                      isVisible && (
                        <Tooltip
                          key={id}
                          overlay={i18n.t(`header.${translation}Overlay`, {
                            value,
                          })}
                          placement="bottom"
                          trigger="hover"
                        >
                          <Flex
                            alignItems="center"
                            data-testid={`app-${id}`}
                            style={{ width: 'auto' }}
                          >
                            <Icon />
                            <TruncatedText
                              marginLeft="xs"
                              style={{ color: colors.gray30 }}
                            >
                              {translation === 'installs' ? (
                                <span>
                                  {value} {i18n.t(`header.${translation}`)}
                                </span>
                              ) : (
                                <span>
                                  {i18n.t(`header.${translation}`)} {value}
                                </span>
                              )}
                            </TruncatedText>
                          </Flex>
                        </Tooltip>
                      )
                  )}

                  {security_questionnaire_visible && (
                    <Flex alignItems="center">
                      <Popover
                        overlay={<SecurityBadge />}
                        placement="bottom"
                        trigger="hover"
                      >
                        <Pill color="blue" data-testid="app-security-badge">
                          <Flex alignItems="center">
                            <ShieldStar />
                            <Box margin="none xs">
                              {i18n.t('header.securityBadge')}
                            </Box>
                          </Flex>
                        </Pill>
                      </Popover>
                    </Flex>
                  )}

                  {status === 'beta' && (
                    <Flex alignItems="center" data-testid="app-beta">
                      <Pill color="magenta">{i18n.t('header.beta')}</Pill>
                    </Flex>
                  )}
                </ResponsiveFlex>
              </Page.Title>
            </Box>

            <Box display="flex" gap="md">
              <Box marginRight="sm">
                {contact_us_email || create_lead ? (
                  <ContactForm
                    appName={name}
                    developerAppId={developer_app_id}
                    contactUsEmail={contact_us_email}
                    createLead={create_lead}
                    email={user?.email}
                    company={companyName}
                    sendAnalytics={sendAnalytics}
                    isSignedIn={user?.isSignedIn}
                    isInstalled={app?.installed}
                  />
                ) : (
                  <LinkButton
                    href={price_button}
                    variant="secondary"
                    size="lg"
                    onClick={() =>
                      sendAnalytics(
                        'click',
                        'Link',
                        'Learn How',
                        'learn_how.clicked'
                      )
                    }
                    target="_blank"
                  >
                    {i18n.t('labels.learnHow')}
                  </LinkButton>
                )}
              </Box>
              {getActionButton()}
            </Box>
          </ResponsiveFlex>
        </HeaderContainer>
      </PageHeader>
    </Page>
  );
};

AppHeader.propTypes = {
  app: PT.shape({
    built_by: PT.string.isRequired,
    contact_us_email: PT.string,
    create_lead: PT.bool,
    developer_app_id: PT.string,
    installCount: PT.string,
    status: PT.string.isRequired,
    name: PT.string.isRequired,
    price_button: PT.string,
    search_description: PT.string.isRequired,
    small_logo: PT.shape({
      url: PT.string,
    }).isRequired,
    security_questionnaire_visible: PT.bool,
    installed: PT.bool,
    direct_installable: PT.bool,
    small_logo_url: PT.string,
    seller: PT.string,
  }),
  companyName: PT.string.isRequired,
  getActionButton: PT.func.isRequired,
  metricsData: PT.shape({
    app_installations: PT.shape({
      rows: PT.arrayOf(PT.shape({})).isRequired,
    }),
  }),
  sendAnalytics: PT.func.isRequired,
  user: PT.shape({
    email: PT.string,
    isSignedIn: PT.bool,
  }).isRequired,
};

export default AppHeader;
